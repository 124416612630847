import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import { DnxSwitchGroup, DnxSwitch } from "../../../loaders/DNXLoader";
import "../accountsMagneticStyle.less";

const AccountsFilter = ({ filterOverlays }) => {
  const {
    allAccounts,
    newAccounts,
    activatedAccounts,
    notActivatedAccounts
  } = i18nMessageBundle;

  const TOGGLER = {
    ALL: "ALL",
    NEW: "NEW",
    ACTIVATED: "ACTIVE",
    NOT_ACTIVATED: "INACTIVE"
  };

  const [toggle, setToggle] = useState(TOGGLER.ALL);
  const setIntervalRefs = useRef(null);
  const handleChange = evt => {
    const swtch = evt.currentTarget;
    setToggle(swtch.value);
    clearTimeout(setIntervalRefs.current);
    setIntervalRefs.current = setTimeout(() => filterOverlays(swtch.value), 0);
  };

  // Clear the timeout when the component unmounts
  useEffect(() => {
    return () => {
      clearTimeout(setIntervalRefs.current);
    };
  }, []);
  return (
    <>
      <div className="accounts-filter-toggler">
        <span>{i18nMessageBundle.showOverlays}</span>
        <DnxSwitchGroup
          value={toggle}
          flavor="toggle-large"
          name="selected_qoe"
          change={handleChange}
        >
          <DnxSwitch name={TOGGLER.ALL}>{allAccounts}</DnxSwitch>
          <DnxSwitch name={TOGGLER.NEW}>{newAccounts}</DnxSwitch>
          <DnxSwitch name={TOGGLER.ACTIVATED}>{activatedAccounts}</DnxSwitch>
          <DnxSwitch name={TOGGLER.NOT_ACTIVATED}>
            {notActivatedAccounts}
          </DnxSwitch>
        </DnxSwitchGroup>
      </div>
    </>
  );
};

AccountsFilter.propTypes = {
  filterOverlays: PropTypes.func
};

export default AccountsFilter;
