import React, { useRef, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import reduxContext from "../reducer";
import * as actions from "../actions";
import AppHoc from "../../../generics/AppHOC";
import {
  DnxTextfield,
  DnxButton,
  DnxDropdown
} from "../../../loaders/DNXLoader";
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import Spinner from "../../../common/Spinner";
import {
  verifyDomainIdentifiers,
  validateIdpFields,
  formatDomainIdentifiers,
  compareObjects
} from "../../../utils/query-utils";
import ErrorComponent from "../../../common/ErrorComponent";
//import css from "./style.less";
import css from "../accountsMagneticStyle.less";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    accounts: state.vanalytics.accounts
  };
};
const Saml = props => {
  const { actions, accounts } = props;
  const { setHideModal, uploadSAML, defineIdpSaml, displaySpinner } = actions;
  const {
    idpData,
    idpAction,
    idpServerData,
    samlData,
    error,
    showSpinner
  } = accounts;
  const [state, setState] = useState({
    idp_issuer_url: "",
    idp_sso_url: "",
    idp_signature_cert_expiry: "",
    default_user_role: "v1;*:basic,o365",
    domain_identifier: "",
    file: idpAction === "Edit IDP" ? false : "",
    idp_certificate: ""
  });

  const [samlProps, setSamlProps] = useState({
    audience_url: "",
    consumer_service_url: ""
  });
  const [domainIdentifier, setDomainIdentifier] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [stateChanged, setStateChanged] = useState(false);
  const inputFile = useRef(null);
  const stateRef = useRef(idpServerData);
  const domainIdentifierRef = useRef(idpServerData.domain_identifier);

  // Check if state changed
  useEffect(() => {
    if (idpAction === "Edit IDP") {
      const stateResult = compareObjects(state, stateRef.current);
      const diResult = compareObjects(
        idpServerData.domain_identifier,
        domainIdentifierRef.current
      );
      if (!stateResult || !diResult) {
        setStateChanged(true);
      } else {
        setStateChanged(false);
      }
    }
  }, [state, domainIdentifier]);

  useEffect(() => {
    if (idpAction === "Define IDP") {
      const samlCopy = { ...state, ...samlData };
      setState(samlCopy);
    } else {
      if (
        idpServerData.domain_identifier &&
        idpServerData.domain_identifier.length > 0
      ) {
        const domain_identifier = formatDomainIdentifiers(
          idpServerData.domain_identifier
        );
        setDomainIdentifier(domain_identifier);
      }
      setSamlProps(idpServerData);
      setState(idpServerData);
    }
  }, [samlData]);

  const validateFormFields = () => {
    const formValidations = validateIdpFields(state);
    if (Object.keys(formValidations).length > 0) {
      setFormErrors(formValidations);
      return false;
    }

    //Case for already added invalid domains
    const isError = verifyDomainIdentifiers(state.domain_identifier);
    if (isError) {
      setValidationErrors({
        domain_identifier: true
      });
      return false;
    } else {
      return true;
    }
  };

  const onSave = event => {
    event.preventDefault();
    const isFormValid = validateFormFields();
    if (isFormValid) {
      const requestPayload = {};
      requestPayload["default_user_role"] = state.default_user_role;
      requestPayload["domain_identifier"] = state.domain_identifier;
      requestPayload["idp_certificate"] = samlData.idp_certificate;
      requestPayload["idp_issuer_url"] = state.idp_issuer_url;
      requestPayload["idp_signature_cert_expiry"] =
        state.idp_signature_cert_expiry;
      requestPayload["idp_sso_url"] = state.idp_sso_url;
      requestPayload["idp_type"] = "SAML";
      requestPayload["overlay_id"] = idpData.overlay_id;
      requestPayload["va_id"] = idpData.va_id;
      if (idpData.smartAccountReference) {
        requestPayload["sa_domain"] = idpData.smartAccountReference.domain_id;
      } else {
        requestPayload["sa_domain"] = idpData.domain_id;
      }

      if (idpAction !== "Define IDP") {
        requestPayload["idp_id"] = state.idp_id;
      }
      defineIdpSaml(requestPayload);
      displaySpinner({
        showSpinner: true
      });
    }
  };

  const onClose = () => {
    setHideModal();
  };

  const openFileInput = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onFileSelect = e => {
    const { value, files } = e.target;
    if (files.length > 0) {
      setState({ ...state, file: value });
      setFormErrors({
        ...formErrors,
        file: value !== "" ? false : true
      });
      const reader = new FileReader();
      reader.onload = event => {
        const fileContent = event.target.result;
        const saDomain = idpData.smartAccountReference
          ? idpData.smartAccountReference.domain_id
          : idpData.domain_id;
        uploadSAML({
          va_id: idpData.va_id,
          sa_domain: saDomain,
          metadata: fileContent
        });
        displaySpinner({
          showSpinner: true
        });
      };
      reader.readAsText(files[0]);
    }
  };

  const formItemChange = e => {
    const { name, value } = e.currentTarget;
    if (name === "domain_identifier") {
      const validDomains = verifyDomainIdentifiers(value);
      if (validDomains) {
        setValidationErrors({
          [name]: true
        });
      } else {
        setValidationErrors({
          [name]: false
        });
      }
    }
    setState(values => ({ ...values, [name]: value }));
    setFormErrors(values => ({
      ...values,
      [name]: value !== "" ? false : true
    }));
  };

  const downloadIDPMetadata = () => {
    // On download SAML metadata
    // @TODO : receive link, then download from link
    if (idpServerData.saml_metadata) {
      let link = document.createElement("a");
      let blob = new Blob([idpServerData.saml_metadata], {
        type: "text/plain"
      });

      link.setAttribute("href", window.URL.createObjectURL(blob));
      link.setAttribute("download", "saml_metadata.xml");

      link.dataset.downloadurl = ["text/plain", link.download, link.href].join(
        ":"
      );
      link.draggable = true;
      link.classList.add("dragout");

      link.click();
    }
  };

  return (
    <>
      {error ? (
        <ErrorComponent className={"large-dashlet-error"} {...error} />
      ) : (
        <form className={css["saml-form"]} onSubmit={onSave}>
          {showSpinner && <Spinner />}
          {idpAction === "Define IDP" && (
            <>
              <div className="link-heading">
                {i18nMessageBundle.idpMetadata}
              </div>
              <DnxButton
                name="fileBtn"
                label={i18nMessageBundle.idpConfigBrowseFile}
                flavor="inline-link"
                click={openFileInput}
                className="saml-metadata"
              ></DnxButton>
              <p className="field-error">
                {formErrors.file ? i18nMessageBundle.idpConfigInvalidXml : ""}
              </p>
              <input
                type="file"
                id="file"
                name="file"
                ref={inputFile}
                onChange={onFileSelect}
                style={{ display: "none" }}
              />
            </>
          )}
          <DnxTextfield
            maxwidth="100%"
            name="idp_Issuer_Url"
            label="IDP Issuer URL"
            value={state.idp_issuer_url}
            change={formItemChange}
            disabled={true}
            required={true}
            errormsg={
              formErrors.idp_issuer_url
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>

          <DnxTextfield
            maxwidth="100%"
            name="idp_sso_url"
            label="IDP Single Sign-on URL"
            value={state.idp_sso_url}
            change={formItemChange}
            disabled={true}
            required={true}
            errormsg={
              formErrors.idp_sso_url
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>

          <DnxTextfield
            maxwidth="100%"
            name="idp_signature_cert_expiry"
            label="IDP Signature Certificate Expiry(days)"
            value={state.idp_signature_cert_expiry}
            change={formItemChange}
            disabled={true}
            required={true}
            errormsg={
              formErrors.idp_signature_cert_expiry
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>

          {idpAction !== "Define IDP" && (
            <div className="saml-metadata">
              <div className="link-heading">
                {i18nMessageBundle.samlMetadata}
              </div>
              <DnxButton
                name="downloadIdp"
                label={i18nMessageBundle.downloadIdpMetadata}
                flavor="inline-link"
                click={downloadIDPMetadata}
              ></DnxButton>
            </div>
          )}

          {idpAction === "View IDP" && (
            <>
              <DnxTextfield
                maxwidth="100%"
                name="consumer_service_url"
                label="Assertion Consumer Service URL"
                value={samlProps.consumer_service_url}
                change={formItemChange}
                disabled={true}
              ></DnxTextfield>

              <DnxTextfield
                maxwidth="100%"
                name="audience_url"
                label="Audience URL"
                value={samlProps.audience_url}
                change={formItemChange}
                disabled={true}
              ></DnxTextfield>
            </>
          )}

          <DnxTextfield
            maxwidth="100%"
            name="default_user_role"
            label="Default User Role"
            value={state.default_user_role}
            change={formItemChange}
            validateon={"oninput"}
            disabled={idpAction === "View IDP"}
            required={true}
            errormsg={
              formErrors.default_user_role
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>

          <DnxDropdown
            maxwidth="100%"
            name="domain_identifier"
            placeholder="Domain Identifier"
            data={idpAction !== "Define IDP" ? domainIdentifier : []}
            value={state.domain_identifier}
            change={formItemChange}
            validateon={"oninput"}
            disabled={idpAction === "View IDP"}
            required
            type="multi"
            flavor="combobox"
            addable="true"
            search-label="Add Domain Identifier"
            errormsg={
              formErrors.domain_identifier
                ? i18nMessageBundle.idpConfigFieldRequired
                : validationErrors.domain_identifier
                ? i18nMessageBundle.idpConfigInvalidDomains
                : ""
            }
          ></DnxDropdown>
          <div className="cta-bar">
            {idpAction !== "View IDP" && (
              <>
                <DnxButton
                  name="formUpdate"
                  label={i18nMessageBundle.idpConfigCancel}
                  flavor="link"
                  type="button"
                  click={onClose}
                ></DnxButton>
                <DnxButton
                  name="formSubmit"
                  label={i18nMessageBundle.idpConfigSave}
                  flavor="primary"
                  type="submit"
                  disabled={idpAction === "Edit IDP" && !stateChanged}
                ></DnxButton>
              </>
            )}
          </div>
        </form>
      )}
    </>
  );
};

Saml.propTypes = {
  actions: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(Saml))
);
