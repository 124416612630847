import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actions";

const reduxContext = createContext();

export default reduxContext;

const storeKey = "vanalytics.accounts";

const initialState = {
  showModal: false,
  idpData: [],
  idpAction: "",
  defineIdpOidc: {},
  samlData: {},
  idpServerData: {},
  idpDefined: false,
  error: null,
  idpDeleted: false,
  showSpinner: false,
  accountUpdated: false
};

const reducer = (state = initialState, action) => {
  const { type, payload, error, overlay } = action;

  if (error) {
    // @TODO : Handle errors
  }

  switch (type) {
    case ACTIONS.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        idpAction: payload.idpAction,
        idpData: payload,
        idpServerData: {},
        samlData: {},
        idpDefined: false,
        error: null,
        idpDeleted: false
      };
    case ACTIONS.HIDE_MODAL:
      return {
        ...state,
        showModal: false,
        idpDeleted: false,
        error: null
      };
    case ACTIONS.DEFINE_IDP_OIDC:
      return {
        ...state,
        idpServerData: payload,
        idpAction: "View IDP",
        idpDefined: true,
        error: null,
        idpDeleted: false
      };
    case ACTIONS.UPLOAD_SAML:
      return {
        ...state,
        samlData: payload,
        error: null,
        idpDeleted: false,
        showSpinner: false
      };
    case ACTIONS.GET_IDP:
      return {
        ...state,
        idpServerData: payload,
        showModal: true,
        idpAction: overlay.idpAction,
        idpData: overlay,
        error: null,
        idpDefined: false,
        idpDeleted: false
      };
    case ACTIONS.DEFINE_IDP_SAML:
      return {
        ...state,
        idpServerData: payload,
        idpAction: "View IDP",
        idpDefined: true,
        error: null,
        idpDeleted: false
      };
    case ACTIONS.SET_ERROR:
      return {
        ...state,
        ...payload
      };
    case ACTIONS.DELETE_IDP:
      return {
        ...state,
        ...payload
      };
    case ACTIONS.IS_IDP_DELETED:
      return {
        ...state,
        ...payload
      };
    case ACTIONS.SHOW_SPINNER:
      return {
        ...state,
        ...payload
      };
    case ACTIONS.UPDATE_ACCOUNT:
      return {
        ...state,
        ...payload
      };
    case ACTIONS.IS_ACCOUNT_UPDATED:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: [
    ACTIONS.SHOW_MODAL,
    ACTIONS.HIDE_MODAL,
    ACTIONS.DEFINE_IDP_OIDC,
    ACTIONS.UPLOAD_SAML,
    ACTIONS.GET_IDP,
    ACTIONS.DEFINE_IDP_SAML,
    ACTIONS.SET_ERROR,
    ACTIONS.DELETE_IDP,
    ACTIONS.IS_IDP_DELETED,
    ACTIONS.SHOW_SPINNER,
    ACTIONS.UPDATE_ACCOUNT,
    ACTIONS.IS_ACCOUNT_UPDATED
  ]
});
