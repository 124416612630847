import Authorization from "../../generics/authorization";
import AccountView from "./AccountView";

const pageProps = {
  workflowContainer: true,
  dashlets: [{
    component: AccountView,
    name: "accountView",
    layout: { x: 0, y: 0, w: 12, h: 45 },
    style: {
      backgroundColor: "var(--hbr-color-neutral-99, #f7f7f7)"
    }
  }]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
