import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { FixedSizeList as List } from "react-window";

import {
  DnxSwitchGroup,
  DnxSwitch,
  DnxSearch,
  DnxLoader
} from "../../../loaders/DNXLoader";
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import ApiService from "../../../config/api-config";
import css from "../accountsMagneticStyle.less";

const SmartAccountTab = props => {
  const { supportAccountState, selectedTab } = props;
  const { originalSaList, isLoaded } = supportAccountState;
  const [filteredSaList, setFilteredSaList] = useState([]);

  const onMenuOpen = async e => {
    if (selectedTab === "smartAccountTab" && e.target.id === "false") {
      const result = await ApiService.getSupportRoleAccounts();

      if (!result.errorObject) {
        const accountDomains = result.data.data.map(accountDomain => {
          return {
            name: accountDomain,
            text: accountDomain
          };
        });

        props.onMenuOpen({
          isLoaded: true,
          originalSaList: accountDomains,
          filteredSaList: accountDomains
        });

        setFilteredSaList(accountDomains);
      } else {
        props.onMenuOpen({
          errorObject: result.errorObject,
          isLoaded: false,
          originalSaList: [
            { name: "none", text: i18nMessageBundle.smartAccountNone }
          ],
          filteredSaList: [
            { name: "none", text: i18nMessageBundle.smartAccountNone }
          ]
        });
      }
    }
  };

  const onTabsChange = e => {
    props.onTabsChange({
      name: e.currentTarget.value,
      smartAccountName: e.target.name
    });
  };

  const OverlayList = ({ index, style }) => {
    let { name, text } = filteredSaList[index];

    return (
      <div className="ListItem" style={style}>
        <DnxSwitch key={name} name={name}>
          {text}
        </DnxSwitch>
      </div>
    );
  };

  const searchSmartAccount = e => {
    const filteredList = originalSaList.filter(sa =>
      sa.text
        .toString()
        .toLowerCase()
        .includes(e.target.value)
    );

    setFilteredSaList(
      filteredList.length > 0
        ? filteredList
        : [{ name: "none", text: i18nMessageBundle.smartAccountNone }]
    );
  };

  return (
    <div className={css["smart-account-tab"]}>
      <DnxSwitchGroup
        reselectable={true}
        value={selectedTab}
        flavor="tabs"
        name="nav-tabs"
        change={onTabsChange}
        menuopen={onMenuOpen}
      >
        <DnxSwitch arialabel="Tabernacles" name="smartAccountTab">
          {i18nMessageBundle.smartAccount}
        </DnxSwitch>
        <DnxSwitch
          arialabel="Tabernacles"
          name="domainAccountTab"
          flavor="drop-button"
          id={isLoaded}
          tab2={isLoaded}
        >
          {i18nMessageBundle.smartAccountSupportRole}
          <DnxSwitchGroup
            flavor="drop-menu-primary"
            name="lazyLoadGroup"
            style={{
              maxHeight: "400px",
              width: "300px",
              overflowX: "hidden",
              overflowY: "scroll"
            }}
          >
            <div id="search-support-role">
              {filteredSaList.length > 0 && (
                <DnxSearch
                  input={searchSmartAccount}
                  placeholder={i18nMessageBundle.smartAccountPlaceHolder}
                />
              )}
            </div>
            {filteredSaList.length ? (
              <List
                className="List"
                height={200}
                itemCount={filteredSaList.length}
                itemSize={40}
                width={300}
              >
                {OverlayList}
              </List>
            ) : (
              <div className="spinner-search-support-role">
                <DnxLoader size="25"></DnxLoader>
              </div>
            )}
          </DnxSwitchGroup>
        </DnxSwitch>
      </DnxSwitchGroup>
    </div>
  );
};

SmartAccountTab.propTypes = {
  supportAccountState: PropTypes.object.isRequired,
  selectedTab: PropTypes.string,
  onMenuOpen: PropTypes.func.isRequired,
  onTabsChange: PropTypes.func.isRequired,
  index: PropTypes.string,
  style: PropTypes.object
};
export default SmartAccountTab;
