import React, { useEffect, useState, useRef, Suspense } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import reduxContext from "../reducer";
import * as actions from "../actions";
import AppHoc from "../../../generics/AppHOC";
import {
  DnxTextfield,
  DnxButton,
  DnxDropdown
} from "../../../loaders/DNXLoader";
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import Spinner from "../../../common/Spinner";
import {
  verifyDomainIdentifiers,
  validateIdpFields,
  formatDomainIdentifiers,
  compareObjects
} from "../../../utils/query-utils";
const ErrorComponent = React.lazy(() =>
  import("../../../common/ErrorComponent")
);
//import css from "./style.less";
import css from "../accountsMagneticStyle.less";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    accounts: state.vanalytics.accounts
  };
};

const Oidc = props => {
  const { actions, accounts } = props;
  const { setHideModal, defineIdpOidc, displaySpinner } = actions;
  const { idpData, idpAction, idpServerData, error, showSpinner } = accounts;
  const [state, setState] = useState({
    client_id: "",
    client_secret: "",
    issuer: "",
    auth_endpoint: "",
    token_endpoint: "",
    jwks_endpoint: "",
    userinfo_endpoint: "",
    default_user_role: "v1;*:basic,o365",
    domain_identifier: ""
  });

  const [domainIdentifier, setDomainIdentifier] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [stateChanged, setStateChanged] = useState(false);
  const stateRef = useRef({ ...idpServerData, client_secret: "" });

  // Check if state changed
  useEffect(() => {
    if (idpAction === "Edit IDP") {
      const stateResult = compareObjects(state, stateRef.current);
      if (!stateResult) {
        setStateChanged(true);
      } else {
        setStateChanged(false);
      }
    }
  }, [state]);

  useEffect(() => {
    if (idpAction !== "Define IDP") {
      if (
        idpServerData.domain_identifier &&
        idpServerData.domain_identifier.length > 0
      ) {
        const domain_identifier = formatDomainIdentifiers(
          idpServerData.domain_identifier
        );
        setDomainIdentifier(domain_identifier);
      }
      const stateRepl = { ...state, ...idpServerData, client_secret: "" };
      setState(stateRepl);
    }
  }, [idpServerData]);

  const validateFormFields = () => {
    const formStr = {
      client_id: state.client_id,
      issuer: state.issuer,
      auth_endpoint: state.auth_endpoint,
      token_endpoint: state.token_endpoint,
      jwks_endpoint: state.jwks_endpoint,
      userinfo_endpoint: state.userinfo_endpoint,
      default_user_role: state.default_user_role,
      domain_identifier: state.domain_identifier
    };

    // Client secret is not mandatory in edit idp usecase
    if (idpAction !== "Edit IDP") {
      formStr.client_secret = state.client_secret;
    }

    const formValidations = validateIdpFields(formStr);
    if (Object.keys(formValidations).length > 0) {
      setFormErrors(formValidations);
      return false;
    }

    //Case for already added invalid domains
    const isError = verifyDomainIdentifiers(state.domain_identifier);
    if (isError) {
      setValidationErrors({
        domain_identifier: true
      });
      return false;
    } else {
      return true;
    }
  };

  const onSave = event => {
    event.preventDefault();
    const isFormValid = validateFormFields();
    if (isFormValid) {
      const requestPayload = { ...state };
      requestPayload["idp_type"] = "OIDC";
      requestPayload["overlay_id"] = idpData.overlay_id;
      requestPayload["va_id"] = idpData.va_id;

      if (idpData.smartAccountReference) {
        requestPayload["sa_domain"] = idpData.smartAccountReference.domain_id;
      } else {
        requestPayload["sa_domain"] = idpData.domain_id;
      }

      if (idpAction !== "Define IDP") {
        requestPayload["idp_id"] = idpData.idp_id;
      }

      defineIdpOidc(requestPayload);
      displaySpinner({
        showSpinner: true
      });
    }
  };

  const onClose = () => {
    setHideModal();
  };

  const formItemChange = e => {
    const { name, value } = e.currentTarget;
    if (name === "domain_identifier") {
      const validDomains = verifyDomainIdentifiers(value);
      if (validDomains) {
        setValidationErrors({
          [name]: true
        });
      } else {
        setValidationErrors({
          [name]: false
        });
      }
    }
    setState(values => ({ ...values, [name]: value }));
    setFormErrors(values => ({
      ...values,
      [name]: value !== "" ? false : true
    }));
  };
  return (
    <>
      {error ? (
        <Suspense fallback={<Spinner />}>
          <ErrorComponent className={"large-dashlet-error"} {...error} />
        </Suspense>
      ) : (
        <form className={css["oidc-form"]} onSubmit={onSave}>
          {showSpinner && <Spinner />}
          <DnxTextfield
            maxwidth="100%"
            name="client_id"
            label="Client ID"
            value={state.client_id}
            change={formItemChange}
            validateon={"oninput"}
            required={true}
            disabled={idpAction === "View IDP" || idpAction === "Edit IDP"}
            errormsg={
              formErrors.client_id
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>
          {idpAction === "Define IDP" && (
            <DnxTextfield
              password={true}
              maxwidth="100%"
              name="client_secret"
              label="Client Secret"
              value={state.client_secret}
              change={formItemChange}
              validateon={"oninput"}
              required={idpAction === "Define IDP" ? true : false}
              disabled={idpAction === "View IDP" || idpAction === "Edit IDP"}
              errormsg={
                formErrors.client_secret
                  ? i18nMessageBundle.idpConfigFieldRequired
                  : ""
              }
            ></DnxTextfield>
          )}
          {idpAction != "Define IDP" && (
            <DnxTextfield
              maxwidth="100%"
              name="redirect_uri"
              label="Redirect URI"
              value={state.redirect_uri}
              disabled={true}
            ></DnxTextfield>
          )}
          <DnxTextfield
            maxwidth="100%"
            name="issuer"
            label="Issuer"
            value={state.issuer}
            change={formItemChange}
            validateon={"oninput"}
            required={true}
            disabled={idpAction === "View IDP" || idpAction === "Edit IDP"}
            errormsg={
              formErrors.issuer ? i18nMessageBundle.idpConfigFieldRequired : ""
            }
          ></DnxTextfield>
          <DnxTextfield
            maxwidth="100%"
            name="auth_endpoint"
            label="Authorization Endpoint"
            value={state.auth_endpoint}
            change={formItemChange}
            validateon={"oninput"}
            required={true}
            disabled={idpAction === "View IDP" || idpAction === "Edit IDP"}
            errormsg={
              formErrors.auth_endpoint
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>
          <DnxTextfield
            maxwidth="100%"
            name="token_endpoint"
            label="Token Endpoint"
            value={state.token_endpoint}
            change={formItemChange}
            validateon={"oninput"}
            required={true}
            disabled={idpAction === "View IDP" || idpAction === "Edit IDP"}
            errormsg={
              formErrors.token_endpoint
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>
          <DnxTextfield
            maxwidth="100%"
            name="jwks_endpoint"
            label="JWKS Endpoint"
            value={state.jwks_endpoint}
            change={formItemChange}
            validateon={"oninput"}
            required={true}
            disabled={idpAction === "View IDP" || idpAction === "Edit IDP"}
            errormsg={
              formErrors.jwks_endpoint
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>
          <DnxTextfield
            maxwidth="100%"
            name="userinfo_endpoint"
            label="Userinfo Endpoint"
            value={state.userinfo_endpoint}
            validateon={"oninput"}
            required={true}
            disabled={idpAction === "View IDP" || idpAction === "Edit IDP"}
            change={formItemChange}
            errormsg={
              formErrors.userinfo_endpoint
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>
          <DnxTextfield
            maxwidth="100%"
            name="default_user_role"
            label="Default User Role"
            value={state.default_user_role}
            change={formItemChange}
            validateon={"oninput"}
            required={true}
            disabled={idpAction === "View IDP"}
            errormsg={
              formErrors.default_user_role
                ? i18nMessageBundle.idpConfigFieldRequired
                : ""
            }
          ></DnxTextfield>
          <DnxDropdown
            maxwidth="100%"
            name="domain_identifier"
            placeholder="Domain Identifier"
            data={idpAction !== "Define IDP" ? domainIdentifier : []}
            value={state.domain_identifier}
            change={formItemChange}
            validateon={"oninput"}
            disabled={idpAction === "View IDP"}
            required
            type="multi"
            flavor="combobox"
            addable="true"
            search-label="Add Domain Identifier"
            errormsg={
              formErrors.domain_identifier
                ? i18nMessageBundle.idpConfigFieldRequired
                : validationErrors.domain_identifier
                ? i18nMessageBundle.idpConfigInvalidDomains
                : ""
            }
          ></DnxDropdown>
          <div className="cta-bar">
            {idpAction !== "View IDP" && (
              <>
                <DnxButton
                  name="formUpdate"
                  label={i18nMessageBundle.idpConfigCancel}
                  flavor="link"
                  type="button"
                  click={onClose}
                ></DnxButton>
                <DnxButton
                  name="formSubmit"
                  label={i18nMessageBundle.idpConfigSave}
                  flavor="primary"
                  type="submit"
                  disabled={idpAction === "Edit IDP" && !stateChanged}
                ></DnxButton>
              </>
            )}
          </div>
        </form>
      )}
    </>
  );
};

Oidc.propTypes = {
  actions: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(Oidc))
);
