import ApiService from "../../config/api-config";

export const ACTIONS = {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
  DEFINE_IDP_OIDC: "DEFINE_IDP_OIDC",
  UPLOAD_SAML: "UPLOAD_SAML",
  GET_IDP: "GET_IDP",
  DEFINE_IDP_SAML: "DEFINE_IDP_SAML",
  SET_ERROR: "SET_ERROR",
  DELETE_IDP: "DELETE_IDP",
  IS_IDP_DELETED: "IS_IDP_DELETED",
  SHOW_SPINNER: "SHOW_SPINNER",
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
  IS_ACCOUNT_UPDATED: "IS_ACCOUNT_UPDATED"
};

export const setShowModal = payload => ({
  type: ACTIONS.SHOW_MODAL,
  payload
});

export const setHideModal = () => ({
  type: ACTIONS.HIDE_MODAL
});

export const defineIdpOidc = payload => dispatch => {
  ApiService.defineIdpOidc(payload).then(res => {
    if (res && !res.errorObject) {
      dispatch({
        type: ACTIONS.DEFINE_IDP_OIDC,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          error: res.errorObject,
          showSpinner: false
        }
      });
    }
  });
};
export const uploadSAML = payload => dispatch => {
  ApiService.uploadSAML(payload).then(res => {
    if (res && !res.errorObject) {
      dispatch({
        type: ACTIONS.UPLOAD_SAML,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          error: res.errorObject,
          showSpinner: false
        }
      });
    }
  });
};

export const getIDP = (payload, overlay) => dispatch => {
  ApiService.getIDP(payload).then(res => {
    if (!res.errorObject) {
      dispatch({
        type: ACTIONS.GET_IDP,
        payload: res.data,
        overlay: overlay
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          error: res.errorObject,
          showModal: true,
          showSpinner: false
        }
      });
    }
  });
};

export const defineIdpSaml = payload => dispatch => {
  ApiService.defineIdpSaml(payload).then(res => {
    if (res && !res.errorObject) {
      dispatch({
        type: ACTIONS.DEFINE_IDP_SAML,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          error: res.errorObject,
          showSpinner: false
        }
      });
    }
  });
};

export const deleteIdp = (payload, hideAlert) => dispatch => {
  ApiService.deleteIdp(payload).then(res => {
    if (!res.errorObject) {
      dispatch({
        type: ACTIONS.DELETE_IDP,
        payload: {
          idpDeleted: true
        }
      });
    } else {
      hideAlert?.();
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          error: res.errorObject,
          showModal: true,
          showSpinner: false
        }
      });
    }
  });
};

export const isIdpDeleted = payload => ({
  type: ACTIONS.IS_IDP_DELETED,
  payload
});

export const displaySpinner = payload => ({
  type: ACTIONS.SHOW_SPINNER,
  payload
});

export const updateAccount = (payload, hideAlert) => dispatch => {
  ApiService.updateAccount(payload).then(res => {
    if (!res.errorObject) {
      dispatch({
        type: ACTIONS.UPDATE_ACCOUNT,
        payload: {
          accountUpdated: true
        }
      });
    } else {
      hideAlert?.();
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          error: res.errorObject,
          showModal: true,
          showSpinner: false
        }
      });
    }
  });
};

export const isAccountUpdated = payload => ({
  type: ACTIONS.IS_ACCOUNT_UPDATED,
  payload
});
