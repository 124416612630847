import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import reduxContext from "../reducer";
import * as actions from "../actions";
import AppHoc from "../../../generics/AppHOC";
import {
  DnxModal,
  DnxSwitchGroup,
  DnxSwitch
} from "../../../loaders/DNXLoader";
import Oidc from "../oidc";
import Saml from "../saml";
import ApiService from "../../../config/api-config";
import ErrorComponent from "../../../common/ErrorComponent";
import css from "./style.less";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    accounts: state.vanalytics.accounts
  };
};
const AccountsModal = props => {
  const { actions, accounts, setAccounts } = props;
  const { setHideModal, displaySpinner } = actions;
  const { idpAction, idpServerData, idpDefined, error } = accounts;

  const [idp, setIdp] = useState("saml");

  useEffect(async () => {
    if (idpDefined) {
      const accounts = await ApiService.getSmartAccountData();
      setAccounts(accounts.data.data);
      displaySpinner({
        showSpinner: false
      });
    }
  }, [idpDefined]);

  const onClose = () => {
    setHideModal();
  };

  const onRadioChange = e => {
    setIdp(e.currentTarget.value);
  };

  return (
    <DnxModal
      attachtobody={true}
      width={"50"}
      fullbrowser={true}
      label={idpAction}
      close={onClose}
      focusselector="#focus-here"
      class="idp-modal-container"
    >
      {error ? (
        <ErrorComponent className={"large-dashlet-error"} {...error} />
      ) : (
        <div className="cta-container">
          <div className="cta-content">
            <div className={css["form-container"]}>
              {idpAction === "Define IDP" && (
                <DnxSwitchGroup
                  name="idp_type"
                  direction="row"
                  change={onRadioChange}
                  value={idp}
                  class="idp-radio-group"
                >
                  <DnxSwitch flavor="radio" name="oidc" label="OIDC IDP" />
                  <DnxSwitch flavor="radio" name="saml" label="SAML IDP" />
                </DnxSwitchGroup>
              )}

              {idpAction === "Define IDP" && idp === "saml" ? (
                <Saml />
              ) : idpAction === "Define IDP" && idp === "oidc" ? (
                <Oidc />
              ) : (
                ""
              )}
              {idpAction !== "Define IDP" &&
              idpServerData.idp_type === "SAML" ? (
                <Saml />
              ) : idpAction !== "Define IDP" &&
                idpServerData.idp_type === "OIDC" ? (
                <Oidc />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </DnxModal>
  );
};

AccountsModal.propTypes = {
  actions: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
  setAccounts: PropTypes.func
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(AccountsModal))
);
