/* eslint no-unused-vars : "off" */
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  lazy,
  Suspense,
  memo
} from "react";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import * as actions from "../actions";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import {
  DnxSearch,
  DnxToast,
  toast,
  DnxBanner
} from "../../../loaders/DNXLoader";
import Table from "dnx-web-components/dist/table.min";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import SmartAccountTab from "../SmartAccountTab";
import AccountsFilter from "../AccountsFilter";
import SmartAccountActions from "../SmartAccountActions";
import AccountsModal from "../AccountsModal";
import apiService from "../../../config/api-config";
import { genericViewFromComponent } from "@cisco-dna/react-utils";
import { getTimePeriod, getTimePeriodELT } from "../../../utils/displayTime";
import {
  setLocalStorageFlag,
  getLocalStorageFlag,
  getPostLoginNotifications,
  createMarkup
} from "../../../utils/common";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../accountsMagneticStyle.less";
import { BASE_URL } from "../../../apis/apiConstants";
import { getCookie } from "../../../serviceWorker";

const NoDataAvailable = lazy(() => import("../../../common/NoDataAvailable"));

const [HbrCard, HbrModal, HbrButton, HbrIcon, HbrSpinner, HbrBanner] = reactWrapper([
  "hbr-card",
  "hbr-modal",
  "hbr-button",
  "hbr-icon",
  "hbr-spinner",
  "hbr-banner"
]);

const setSmartAccountReference = accounts => {
  accounts.forEach(({ overlays, ...obj }) => {
    overlays.forEach(item => item.smartAccountReference = obj);
  });
  return accounts.slice(0);
};

const filterAccounts = (data, searchTxt = "", statusFilter = "ALL") => {
  let filteredData = JSON.parse(JSON.stringify(data));
  const searchText = searchTxt.toLowerCase();
  if (searchText !== "")
    filteredData = filteredData.filter(item => {
      item.overlays = Array.isArray(item.overlays) ?
        item.overlays.filter(val => val.org_name?.includes(searchText))
        : [];
      return item.overlays.length > 0;
    });
  if (statusFilter !== "ALL")
    filteredData = filteredData.filter(item => {
      item.overlays = Array.isArray(item.overlays) ?
        item.overlays.filter(item => item.status === statusFilter)
        : [];
      return item.overlays.length > 0;
    });
  if (filteredData.length === 0)
    filteredData = [i18n.idpConfigNoDataAvailable];
  return filteredData;
};

const AccountView = ({
  globalFilter,
  actions,
  accounts,
  setAccounts,
  setOverlays,
  setPostLoginNotifications,
  setSelectedSite,
  setCtrlTimeFilter,
  setAltoProps,
  setAvailableFeatureFlags
}) => {
  const {
    setShowModal,
    getIDP,
    deleteIdp,
    isIdpDeleted,
    updateAccount,
    isAccountUpdated
  } = actions;

  const DnxTable = DtableWrapper(Table);
  const tableRef = useRef();
  const refModal = useRef(null);

  const setIntervalRefs = useRef({
    setOverlaysRef: null
  });
  const eventListenersRef = useRef([]); // Array to store event listenerss
  let history = useHistory();
  const [smartAccounts, setSmartAccounts] = useState([]);
  const [showSpinner, setSpinner] = useState(false);
  const [prompt, setPrompt] = useState({
    showPrompt: false,
    message: "",
    data: {},
    buttons: [],
    title: ""
  });
  const { isSALoadedandNoData, isSupportRole } = globalFilter;
  const [supportAccountState, setSupportAccountState] = useState({
    filteredSaList: [],
    originalSaList: [],
    isLoaded: false
  });
  const [selectedTab, setSelectedTab] = useState("smartAccountTab");
  const [smartAccountName, setSmartAccountName] = useState("");
  const [domainAccount, setDomainAccount] = useState();
  const [isSupportRoleSpinner, setIsSupportRoleSpinner] = useState(true);
  const isDomainAccountTab = selectedTab === "domainAccountTab";
  const [errorState, setErrorState] = useState({ isError: false });
  const accountFilterRef = useRef("ALL");
  const searchFilterValueRef = useRef("");
  const saRef = useRef(JSON.parse(JSON.stringify(
    setSmartAccountReference(globalFilter.accounts)
  )));
  const daRef = useRef([]);

  useEffect(() => {
    isIdpDeleted({
      idpDeleted: false
    });
    const saVaAccounts = setSmartAccountReference(globalFilter.accounts);
    saRef.current = JSON.parse(JSON.stringify(saVaAccounts));
    setSmartAccounts(filterAccounts(
      saRef.current,
      searchFilterValueRef.current,
      accountFilterRef.current
    ));
  }, [globalFilter.accounts]);

  useEffect(() => {
    setSpinner(false);
  }, [accounts.showModal]);

  useEffect(async () => {
    if (accounts.idpDeleted || accounts.accountUpdated) {
      let accountData = [];
      let vaAccountsData = [];
      if (selectedTab === "domainAccountTab") {
        accountData = await apiService.getDomainAccountInfo(
          smartAccountName
        );
        vaAccountsData = accountData?.data?.data || [];
        daRef.current = setSmartAccountReference(vaAccountsData);
        setDomainAccount(filterAccounts(
          daRef.current,
          searchFilterValueRef.current,
          accountFilterRef.current
        ));
      } else {
        accountData = await apiService.getSmartAccountData();
        vaAccountsData = accountData?.data?.data || [];
        setSmartAccounts(filterAccounts(
          setSmartAccountReference(vaAccountsData),
          searchFilterValueRef.current,
          accountFilterRef.current
        ));
        setAccounts(vaAccountsData);
      }

      setSpinner(false);
      setPrompt(values => ({
        ...values,
        showPrompt: false,
        message: "",
        data: {},
        buttons: [],
        title: ""
      }));
      if (accounts.accountUpdated) {
        toast({
          message: i18n.idpConfigUpdateSuccessMessage,
          flavor: "success"
        });
      }
      if (accounts.idpDeleted) {
        toast({
          message: i18n.idpConfigDeleteSuccessMessage,
          flavor: "success"
        });
      }
    }
  }, [accounts.idpDeleted, accounts.accountUpdated]);

  useEffect(() => {
    if (!isDomainAccountTab) {
      const saveAccounts = setSmartAccountReference(globalFilter.accounts);
      setSmartAccounts(saveAccounts);
    }
  }, [selectedTab]);

  const onOverlaySelect = async row => {
    const isUIconvergence = getCookie("cl-analytics");

    let defaultTimePeriodSelected = isUIconvergence ? "1" : "12";

    // added for remove features object from store when click overlays to hide 
    // the security tab (Fix for JIRA id - 11296) 
    setAvailableFeatureFlags();

    localStorage.removeItem("bandForecastingTab");
    localStorage.removeItem("onboardingStatus");

    setLocalStorageFlag("currentOverlay", row.overlay_id);
    setLocalStorageFlag("currentOverlayName", row.org_name);
    setLocalStorageFlag("roles", JSON.stringify(row.roles));
    setLocalStorageFlag("timePeriodSelected", defaultTimePeriodSelected);

    if (isSupportRole) {
      let overlays = [];

      if (selectedTab !== "domainAccountTab") {
        overlays = smartAccounts
          .flatMap(smartAccount => smartAccount.overlays)
          .filter(data => data.overlay_id)
          .map(data => ({
            key: data.overlay_id,
            value: data.org_name,
            status: data.status,
            roles: data.roles
          }));
      } else {
        overlays =
          domainAccount && domainAccount[0]
            ? domainAccount[0].overlays
              .filter(data => data.overlay_id)
              .map(data => ({
                key: data.overlay_id,
                value: data.org_name,
                status: data.status,
                roles: data.roles
              }))
            : [];
      }
      overlays?.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      );
      clearTimeout(setIntervalRefs.current.setOverlaysRef);
      setIntervalRefs.current.setOverlaysRef = setTimeout(
        () => setOverlays(overlays),
        0
      );
    }
    getPostLoginNotifications(setPostLoginNotifications);
    history.push(`${BASE_URL}/overview`);
  };

  // Clear the timeout when the component unmounts
  useEffect(() => {
    const isUIconvergence = getCookie("cl-analytics");

    let defaultTimePeriodSelected = isUIconvergence ? "1" : "12";

    setSelectedSite(-1);

    sessionStorage.removeItem("isGetInfoApiCalled");

    const timePeriod = getTimePeriodELT(defaultTimePeriodSelected);

    setAltoProps({ predictiveTabSelected: "pprTab", status: "" });
    setCtrlTimeFilter(timePeriod, defaultTimePeriodSelected);

    return () => {
      clearTimeout(setIntervalRefs.current.setOverlaysRef);
      eventListenersRef.current.forEach(({ element, event, handler }) => {
        element.removeEventListener(event, handler);
      });
      eventListenersRef.current = [];
    };
  }, []);

  const searchOverlays = event => {
    const { value } = event.detail || "";
    const searchSmartAccounts = JSON.parse(
      JSON.stringify(saRef.current)
    );
    searchFilterValueRef.current = value;
    const smartAcc = searchSmartAccounts.filter(sa => {
      // checks if sa has va's matching the searched string
      const hasValidVa =
        sa.overlays &&
        sa.overlays.some(
          va =>
            va.org_name.toLowerCase().includes(value.toLowerCase()) &&
            (accountFilterRef.current === "ALL" ||
              va.status === accountFilterRef.current)
        );
      if (hasValidVa) {
        sa.overlays = sa.overlays.filter(
          va =>
            va.org_name.toLowerCase().includes(value.toLowerCase()) &&
            (accountFilterRef.current === "ALL" ||
              va.status === accountFilterRef.current)
        );
        return true;
      }
      return false;
    });
    if (smartAcc.length === 0) {
      setSmartAccounts(["No data available"]);
    } else {
      setSmartAccounts(smartAcc);
    }
  };

  const filterOverlays = value => {
    let accountArray = [];
    accountFilterRef.current = value;
    if (isDomainAccountTab) {
      accountArray = JSON.parse(JSON.stringify(daRef.current));
    } else {
      accountArray = JSON.parse(JSON.stringify(saRef.current));
    }

    if (searchFilterValueRef.current === "" && value === "ALL") {
      isDomainAccountTab
        ? setDomainAccount([...daRef.current])
        : setSmartAccounts([...saRef.current]);
      return;
    }

    const filteredAccounts = accountArray.filter(account => {
      // checks if account has va's matching the searched string
      const hasValidVa =
        account.overlays &&
        account.overlays.some(
          va =>
            va.org_name
              .toLowerCase()
              .includes(searchFilterValueRef.current.toLowerCase()) &&
            (value === "ALL" || va.status === value)
        );
      if (hasValidVa) {
        account.overlays = account.overlays.filter(
          va =>
            va.org_name
              .toLowerCase()
              .includes(searchFilterValueRef.current.toLowerCase()) &&
            (accountFilterRef.current === "ALL" ||
              va.status === accountFilterRef.current)
        );
        return true;
      }
      return false;
    });
    if (filteredAccounts.length === 0) {
      isDomainAccountTab
        ? setDomainAccount([i18n.idpConfigNoDataAvailable])
        : setSmartAccounts([i18n.idpConfigNoDataAvailable]);
    } else {
      isDomainAccountTab
        ? setDomainAccount(filteredAccounts)
        : setSmartAccounts(filteredAccounts);
    }
  };

  const handleActionClick = (e, row) => {
    // If overlay actions
    const status = e.target.innerText;
    const overlay = { ...row };
    overlay["idpAction"] = status;
    if (status === "Define IDP") {
      setShowModal(overlay);
    } else if (status === "View IDP" || status === "Edit IDP") {
      const {
        idp_type,
        idp_id,
        va_id,
        overlay_id,
        smartAccountReference,
        domain_id
      } = overlay;
      const query = {};
      query.idp_type = idp_type;
      query.idp_id = idp_id;
      query.va_id = va_id;
      query.overlay_id = overlay_id;
      if (smartAccountReference) {
        query.sa_domain = smartAccountReference.domain_id;
      } else {
        if (!domain_id && domainAccount.length) {
          query.sa_domain = domainAccount[0].domain_id;
          query.domain_id = domainAccount[0].domain_id;
        } else {
          query.sa_domain = domain_id;
          query.domain_id = domain_id;
        }
      }
      setSpinner(true);
      getIDP(query, overlay);
    } else if (status === "Delete IDP") {
      isIdpDeleted({
        idpDeleted: false
      });
      const {
        org_name,
        smartAccountReference,
        name,
        idp_id,
        domain_id,
        va_id
      } = overlay;
      const alertMessage =
        org_name && org_name !== ""
          ? smartAccountReference.name + " > " + org_name
          : name;
      let payload = {
        idp_id: idp_id,
        sa_domain: domain_id ? domain_id : smartAccountReference.domain_id
      };
      if (!domain_id) {
        payload["va_id"] = va_id;
      }
      setPrompt(values => ({
        ...values,
        showPrompt: true,
        message:
          i18n.idpConfigDeleteIdpPromptMessage +
          " " +
          `<b class='alert-message'>${alertMessage}</b>`,
        data: payload,
        buttons: ["Cancel", "Delete"],
        title: "Delete"
      }));
    } else if (status === "Activate" || status === "Activated") {
      isAccountUpdated({
        accountUpdated: false
      });
      const {
        org_name,
        smartAccountReference,
        name,
        domain_id,
        va_id,
        overlay_id
      } = overlay;
      const alertMessage =
        org_name && org_name !== ""
          ? smartAccountReference.name + " > " + org_name
          : name;

      let payload = {
        query: {
          rules: {
            o_id: overlay_id
          }
        },
        update_field: {
          status: "Active"
        },
        sa_domain: domain_id ? domain_id : smartAccountReference?.domain_id,
        va_id: va_id
      };

      setPrompt(values => ({
        ...values,
        showPrompt: true,
        message:
          i18n.idpConfigActivateOverlayPromptMessage +
          " " +
          `<b class='alert-message'>${alertMessage}</b>`,
        data: payload,
        buttons: ["Cancel", "Activate"],
        title: "Activate"
      }));
    } else if (status === "Deactivate" || status === "Inactive") {
      isAccountUpdated({
        accountUpdated: false
      });
      const {
        org_name,
        smartAccountReference,
        name,
        domain_id,
        va_id,
        overlay_id
      } = overlay;
      const alertMessage =
        org_name && org_name !== ""
          ? smartAccountReference.name + " > " + org_name
          : name;

      let payload = {
        query: {
          rules: {
            o_id: overlay_id
          }
        },
        update_field: {
          status: "Inactive"
        },
        sa_domain: domain_id ? domain_id : smartAccountReference?.domain_id,
        va_id: va_id
      };

      setPrompt(values => ({
        ...values,
        showPrompt: true,
        message:
          i18n.idpConfigDeactivateOverlayPromptMessage +
          " " +
          `<b class='alert-message'>${alertMessage}</b>`,
        data: payload,
        buttons: ["Cancel", "Deactivate"],
        title: "Deactivate"
      }));
    }
  };

  // Hide Model
  const hideAlert = useCallback(
    () =>
      setPrompt(values => ({
        ...values,
        showPrompt: false,
        message: "",
        buttons: [],
        title: ""
      })),
    []
  );

  const onClickCancel = () => {
    refModal.current.element.hide();
    hideAlert();
  };

  const overlayDismiss = event => {
    event.preventDefault();
  };

  const onSubmit = (event, title) => {
    setSpinner(true);
    if (title === "Delete") {
      deleteIdp(prompt.data, hideAlert);
    }
    if (title === "Activate" || title === "Deactivate") {
      updateAccount(prompt.data, hideAlert);
    }
  };

  const getTableProps = account => {
    /**
     * TOOLS CONFIG
     */
    const tools = [
      {
        ref: {
          name: "title",
          config: {
            text: account.name,
            count: true
          }
        }
      },
      {
        factory: genericViewFromComponent(SmartAccountActions),
        level: "primary",
        justify: "start"
      }
    ];
    /**
     * COLUMNS CONFIG
     */
    const columns = [
      {
        data: "org_name",
        title: i18n.accountsOverlay,
        width: "35%",
        render: (data, type, { org_name, status }) =>
          status.toLowerCase() === "active"
            ? `<hbr-link variant="text" data-cy=${org_name} id='overlay' class="table-link">${org_name}</hbr-link>`
            : org_name,
        createdCell: (td, cellData, rowData, row, col) => {
          td.addEventListener("click", event => {
            event.preventDefault();
            eventListenersRef.current.push({
              element: td,
              event: "click",
              handler: onOverlaySelect
            });
            event.target.id === "overlay" && onOverlaySelect(rowData);
          });
        }
      },
      {
        data: "status",
        title: i18n.accountsOverlayStatus,
        width: "25%",
        render: data => {
          if (data === "INACTIVE") {
            return "Inactive (Paused)";
          } else
            return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
        }
      },
      {
        data: "idp_defined",
        title: i18n.accountsOverlayDefined,
        width: "25%",
        render: data => {
          return data === "False" ? "Not Defined" : "Defined";
        }
      },
      {
        data: "va_id",
        title: i18n.accountsOverlayAction,
        width: "15%",
        createdCell: (td, vaId, rowData) => {
          td.addEventListener("click", event => {
            event.preventDefault();
            eventListenersRef.current.push({
              element: td,
              event: "click",
              handler: handleActionClick
            });
            handleActionClick(event, rowData);
          });
        },
        render: function (data, type, row, meta) {
          const id = `${meta.row}-${meta.col}`;
          const hasOverlayId = row.overlay_id && row.overlay_id.length;
          const actions = [];

          // Need admin role and overlay_id to create, view, and edit idp
          const idpValid = row.roles.includes("admin") && hasOverlayId;
          if (idpValid && row.idp_defined === "False") {
            actions.push({
              label: i18n.defineIdp,
              name: "define-idp-menu-button"
            });
          }
          if (idpValid) {
            if (row.status === "ACTIVE") {
              actions.push({
                label: i18n.deActivate,
                name: "deactivate-idp-menu-button"
              });
            }
          }
          if (row.status === "DEACTIVATED" || row.status === "INACTIVE") {
            actions.push({
              label: i18n.activate,
              name: "activate-idp-menu-button"
            });
          }
          if (row.idp_defined === "True") {
            actions.push({
              label: i18n.viewIdp,
              name: "view-idp-menu-button"
            });
            actions.push({
              label: i18n.editIdp,
              name: "edit-idp-menu-button"
            });
            actions.push({
              label: i18n.deleteIdp,
              name: "delete-idp-menu-button"
            });
          }

          return row.status === "NEW" || !actions.length
            ? null
            : `<dnx-switch-group flavor="action-dropdown" name='fabric-transit-menu'>
                <dnx-switch name='fabric-transit-menu-button-${data}-${id}' flavor='drop-button' class="dnx-hide-arrow">
                    <dnx-icon name="more-horizontal" style="cursor: pointer;"></dnx-icon>
                    <dnx-switch-group flavor="drop-menu-primary" name="planet-earth" data-id="${data}">
                    ${actions
              .map(
                action =>
                  `<dnx-switch id="${id}-${action.name}" name="${action.name}" arialabel="deactivate" class="dnx-switch-cursor">${action.label}</dnx-switch>`
              )
              .toString()
              .replaceAll(",", "")}
                    </dnx-switch-group>
                </dnx-switch>
            </dnx-switch-group>`;
        }
      }
    ];

    const tableConfig = {
      id: "table-serverSide",
      columns: columns,
      tools: tools,
      data: [],
      dataTableClasses: "nowrap",
      dtOptions: {
        scrollY: 200,
        scrollX: true,
        order: [[0, "desc"]],
        serverSide: true,
        columnDefs: [
          {
            targets: [3],
            orderable: false
          }
        ],
        ajax: (data, callback, settings) => {
          let overlays = account.overlays ? account.overlays : [];
          // Calculate the start and end indexes based on the scroll position
          let startIndex = data.start;
          let endIndex = data.start + data.length;

          // sort the filtered data based on the sorting column and direction
          let sortColumn = columns[data.order[0].column];
          const columnName = sortColumn.data;
          if (sortColumn && columnName) {
            const sortDirection = data.order[0].dir;
            if (sortDirection === "asc")
              overlays.sort((item1, item2) =>
                item1[columnName] < item2[columnName] ? -1 : 1
              );
            else
              overlays.sort((item1, item2) =>
                item2[columnName] < item1[columnName] ? -1 : 1
              );
          }
          // Slice the data array based on the start and end indexes
          let newData = overlays.slice(startIndex, endIndex);

          // Update the table with the sliced data array
          callback({
            data: newData,
            recordsTotal: overlays.length,
            recordsFiltered: overlays.length
          });
        }
      },
      handleActionClick: handleActionClick,
      account: account
    };
    return tableConfig;
  };

  const onMenuOpen = state => {
    if (state.errorObject) {
      setErrorState({
        isError: true,
        message: `${state.errorObject.message}.
        Failed with error code ${state.errorObject.errorCode}.
        Try again. Reload the page.`
      });

      setSupportAccountState({
        ...supportAccountState,
        ...state
      });
    } else {
      setSupportAccountState({
        ...supportAccountState,
        ...state
      });
    }
  };

  const onTabsChange = async tab => {
    searchFilterValueRef.current = "";
    accountFilterRef.current = "ALL";
    if (tab.name === "domainAccountTab") {
      setSelectedTab(tab.name);
      setIsSupportRoleSpinner(true);
      setSmartAccountName(tab.smartAccountName);

      const accountDetails = await apiService.getDomainAccountInfo(
        tab.smartAccountName
      );

      if (!accountDetails.errorObject) {
        const saVaAccounts = setSmartAccountReference(accountDetails.data.data);
        daRef.current = setSmartAccountReference(accountDetails.data.data);
        setDomainAccount(saVaAccounts);
      } else {
        const emptyAccount = [
          {
            domain_id: "",
            idp_defined: "True",
            idp_id: "",
            idp_level: "",
            idp_status: "",
            idp_type: "",
            name: "",
            overlays: []
          }
        ];
        daRef.current = emptyAccount;
        setDomainAccount(emptyAccount);
        setErrorState({
          isError: true,
          message: `${accountDetails.errorObject.message}.
          Failed with error code ${accountDetails.errorObject.errorCode}.
          Select a new domain account from the Support User Acccount`
        });
      }

      setIsSupportRoleSpinner(false);
    } else {
      setSelectedTab(tab.name);
    }
  };

  if (smartAccounts && smartAccounts.length > 0) {
    return (
      <>
        <DnxToast name="idp-delete-notify" />
        {getLocalStorageFlag("infoAPIFailed") && (
          <div className="domain-account-banner">
            <DnxBanner
              name="banner-error"
              config={[
                {
                  message: JSON.parse(getLocalStorageFlag("infoAPIFailed"))
                    .message,
                  type: "error"
                }
              ]}
            />
          </div>
        )}
        {getLocalStorageFlag("isAvailableFeaturesMissing") && (
          <div className="domain-account-banner">
            <HbrBanner
              sentiment="warning"
              variant="fill"
              open
            >
              <HbrIcon slot="icon" name="hbr-error-filled"></HbrIcon>
              <span className="banner-text">{i18n.missingAvailableFeaturesFlag}</span>
            </HbrBanner>
          </div>
        )}
        <div className="smart-accounts-container">
          <div className={css["smart-accounts"]}>
            {errorState.isError && (
              <div className="domain-account-banner">
                <DnxBanner
                  name="banner-error"
                  config={[
                    {
                      message: errorState.message,
                      type: "error"
                    }
                  ]}
                />
              </div>
            )}
            {showSpinner && (
              <div data-cy="spinner-accounts-view">
                <Spinner />
              </div>
            )}
            {prompt.showPrompt && (
              <HbrModal
                ref={refModal}
                open={true}
                onHbr-overlay-dismiss={overlayDismiss}
                id="accounts-actions-modal"
              >
                <div slot="label">
                  <HbrIcon
                    name="alerts-warning-large"
                    sentiment="warning"
                  ></HbrIcon>{" "}
                  {prompt.title}
                </div>
                <div dangerouslySetInnerHTML={createMarkup(prompt.message)} />
                <div slot="footer">
                  <HbrButton
                    variant="text"
                    style={{ marginRight: "16px" }}
                    onClick={onClickCancel}
                    disabled={showSpinner}
                  >
                    {prompt.buttons[0]}
                  </HbrButton>
                  <HbrButton
                    variant="fill"
                    onClick={e => onSubmit(e, prompt.title)}
                    sentiment={prompt.buttons[1] === "Activate" ? "" : "danger"}
                    disabled={showSpinner}
                  >
                    {showSpinner && <HbrSpinner slot="prefix"></HbrSpinner>}
                    {prompt.buttons[1]}
                  </HbrButton>
                </div>
              </HbrModal>
            )}
            {accounts.showModal && <AccountsModal />}
            {isSupportRole && supportAccountState.originalSaList ? (
              <div className="smart-account-tabs">
                <SmartAccountTab
                  onMenuOpen={onMenuOpen}
                  onTabsChange={onTabsChange}
                  supportAccountState={supportAccountState}
                  selectedTab={selectedTab}
                />
              </div>
            ) : (
                <div className="hbr-type-h2">
                  {i18n.smartAccount}
                </div>
              )}
            {!isDomainAccountTab && (
              <>
                <div>
                  <DnxSearch
                    search={searchOverlays}
                    placeholder={i18n.smartAccountSearch}
                  />
                </div>
                <div>
                  <AccountsFilter filterOverlays={filterOverlays} />
                </div>
              </>
            )}
            {isDomainAccountTab && domainAccount && (
              <AccountsFilter filterOverlays={filterOverlays} />
            )}

            {!isDomainAccountTab ? (
              smartAccounts.map((account, index) => {
                const tableProps = getTableProps(account);
                return (
                  /* Smart Accounts List */
                  <div
                    key={index}
                    className="accounts-table"
                    data-cy="accountsTableView"
                  >
                    <HbrCard style={{ width: "100%" }}>
                      <DnxTable {...tableProps} ref={tableRef} />
                    </HbrCard>
                  </div>
                );
              })
            ) : isSupportRoleSpinner ? (
              <div className="spinner-container">
                <Spinner />
              </div>
            ) : (
                  domainAccount.map((account, index) => {
                    const tableProps = getTableProps(account);
                    return (
                      /* Support User Accounts List */
                      <div
                        key={index}
                        className="accounts-table"
                        data-cy="accountsTableView"
                      >
                        <HbrCard style={{ width: "100%" }}>
                          <DnxTable {...tableProps} ref={tableRef} />
                        </HbrCard>
                      </div>
                    );
                  })
                )}
          </div>
        </div>
      </>
    );
  } else {
    if (isSALoadedandNoData) {
      return (
        <Suspense fallback={<Spinner />}>
          <NoDataAvailable />
        </Suspense>
      );
    } else {
      return (
        <div className="spinner-container">
          <Spinner />
        </div>
      );
    }
  }
};

const arePropsEqual = (prevProps, nextProps) => {
  // Destructure the relevant properties from prevProps and nextProps
  const {
    globalFilter: { accounts: prevAccounts },
    accounts: {
      showModal: prevShowModal,
      idpDeleted: prevIdpDeleted,
      accountUpdated: prevAccountUpdated
    }
  } = prevProps;

  const {
    globalFilter: { accounts: nextAccounts },
    accounts: {
      showModal: nextShowModal,
      idpDeleted: nextIdpDeleted,
      accountUpdated: nextAccountUpdated
    }
  } = nextProps;
  // Compare the relevant properties for memoization
  const arrayAccountsEqual = JSON.stringify(prevAccounts) === JSON.stringify(nextAccounts);
  const accountsLengthEqual = prevAccounts.length === nextAccounts.length;
  const showModalEqual = prevShowModal === nextShowModal;
  const idpDeletedEqual = prevIdpDeleted === nextIdpDeleted;
  const accountUpdatedEqual = prevAccountUpdated === nextAccountUpdated;
  // Return true only if all properties are equal, memoizing the component
  return (
    arrayAccountsEqual &&
    accountsLengthEqual &&
    showModalEqual &&
    idpDeletedEqual &&
    accountUpdatedEqual
  );
};

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || "Component";

const withNoRerendering = WrappedComponent => {
  const MemoizedComponent = memo(
    props => <WrappedComponent {...props} />,
    arePropsEqual
  );
  MemoizedComponent.displayName = `WithNoRerendering(${getDisplayName(
    WrappedComponent
  )})`;
  return MemoizedComponent;
};

const MemoizedAccountView = withNoRerendering(AccountView);

AccountView.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  accounts: PropTypes.object,
  setAccounts: PropTypes.func,
  setAvailableFeatureFlags: PropTypes.func,
  setCtrlTimeFilter: PropTypes.func,
  setOverlays: PropTypes.func,
  setPostLoginNotifications: PropTypes.func,
  setAggregateAppData: PropTypes.func.isRequired,
  setAppFamilySLA: PropTypes.func.isRequired,
  setSelectedSite: PropTypes.func,
  setAltoProps: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({ accounts: state.vanalytics.accounts });

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(MemoizedAccountView))
);
