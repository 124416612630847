import React from "react";
import { PropTypes } from "prop-types";
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import css from "../accountsMagneticStyle.less";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

const SmartAccountActions = props => {
  const [HbrButton] = reactWrapper(["hbr-button"]);
  const { account, handleActionClick } = props.table.props;

  if (account && account.roles && account.roles.includes("admin")) {
    if (account.idp_defined === "True") {
      return (
        <div className={css["smart-account-idp"]}>
          <span>{i18nMessageBundle.idpConfigIdpDefined}</span>
          <HbrButton
            name={account.domain_id}
            id={account.domain_id}
            onClick={e => handleActionClick(e, account)}
            variant={"text"}
          >
            {i18nMessageBundle.editIdp}
          </HbrButton>
          <HbrButton
            name={account.domain_id}
            id={account.domain_id}
            onClick={e => handleActionClick(e, account)}
            variant={"text"}
          >
            {i18nMessageBundle.deleteIdp}
          </HbrButton>
        </div>
      );
    } else {
      return (
        <div className={css["smart-account-idp"]}>
          <span>{i18nMessageBundle.idpConfigIdpNotDefined}</span>
          <HbrButton
            name={account.domain_id}
            id={account.domain_id}
            onClick={e => handleActionClick(e, account)}
            variant={"text"}
          >
            {i18nMessageBundle.defineIdp}
          </HbrButton>
        </div>
      );
    }
  } else {
    return null;
  }
};

SmartAccountActions.propTypes = {
  table: PropTypes.object
};

export default SmartAccountActions;
